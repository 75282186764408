import React, { useEffect, useRef, useState } from 'react';
import { BackButton, Carregando, ContainerItem } from '../../utils/componentes'
import { api, baseURL } from '../../utils/api'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import moment from 'moment'
import './style.css'
import { useParams } from 'react-router-dom';
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import logo from '../../assets/logoElox.png'
class ComponentToPrint extends React.PureComponent {

  render() {

    return (
      <div className="page">
        <div style={{ margin: 35, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <p className="dataEstoque">Estoque realizado em {moment().format('DD/MM/YYYY HH:mm')}</p>
          <h3>Data: {this.props.data}</h3>
          <div className="row header" style={{ marginBottom: 10 }}>
            <div style={{ width: '30%' }}>
              <p >Referência</p>
            </div>
            <div style={{ width: '25%' }}>
              <p >Descrição</p>
            </div>
            <div style={{ width: '25%' }}>
              <p >Marca</p>
            </div>
            <div style={{ width: '20%' }}>
              <p style={{ textAlign: 'right', paddingRight: 10 }}>Qnt</p>
            </div>
          </div>
          {
            this.props.produtos.map((el, index) =>

              <div key={el.id} className={'row'} style={{ fontWeight: (el.quantidade) < 0 ? 'bold' : 'normal', backgroundColor: (el.quantidade) < 0 ? '#ccc' : 'white' }} >
                <div className="rowProduto" style={{ width: '30%', }}>
                  <p>{el.referencia} </p>
                </div>
                <div className="rowProduto" style={{ width: '25%' }}>
                  <p>{el.descricao}</p>
                </div>
                <div className="rowProduto" style={{ width: '25%' }}>
                  <p>{el.marca}</p>
                </div>
                <div className="rowProduto" style={{ width: '20%', justifyContent: 'flex-end', paddingRight: 10 }}>
                  <p>{(el.quantidade)}</p>
                </div>
              </div>
            )
          }
          <p className="total">Total: {this.props.produtos
            .filter(el => el.quantidade > 0)
            .reduce((ac, arr) => { return ac + arr.quantidade }, 0)}</p>
          <p className="total">Peso: {this.props.produtos
            .filter(el => el.quantidade > 0)
            .reduce((ac, arr) => { return ac + (arr.quantidade * arr.peso) }, 0).toLocaleString('pt-BR', { currency: 'BRL', minimumIntegerDigits: 2, maximumFractionDigits: 2 })} Kg</p>
          <div style={{ width: '100%' }}>
            <span>Observações:</span>
            <p>{this.props.obs}</p>

          </div>
        </div>
      </div>
    )
  }
}
function Index(props) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let { id, funcao } = useParams()
  useEffect(() => {
    const getDados = async () =>
      await api.get(`/?funcao=${funcao}&id=${id}&token=${localStorage.getItem('token')}`)
        .then(async (data) => {
          let dados = data.data[0]
          let info = JSON.parse(data.data[0].dados)
          let produtos = JSON.parse(info.quantidade);
          setInfo(info)
          setDados(dados)
          setProdutos(produtos)
        })
        .catch(err => console.log(err))

    getDados()
  }, [id, funcao])
  const [info, setInfo] = useState()
  const [dados, setDados] = useState()
  const [produtos, setProdutos] = useState([])
  if (produtos.length <= 0)
    return <Carregando />
  return (
    <>
      <div className="containerInfoBateria">
        <BackButton />
        <div className="containerItems">

          <div>
            <p>{moment(info.data_).format('DD/MM/YYYY HH:mm')}</p>
            <p style={{ fontSize: 28, fontWeight: 'bold', color: '#aaa' }}>{info.nome}</p>
            <p style={{ fontSize: 18, color: '#aaa' }}>{info.municipio}-{info.uf}</p>
            <p style={{ fontSize: 22, fontWeight: "bold", color: '#e74c3c' }}>Pedido: {info.pedido}</p>
            <p style={{ fontSize: 22, fontWeight: "bold", color: '#e74c3c' }}>Peso: {produtos.reduce((acum, arr) => { return acum + (parseInt(arr.quantidade * arr.peso)) }, 0).toLocaleString('pt-BR', { currency: 'BRL', minimumIntegerDigits: 2, maximumFractionDigits: 2 })} kg</p>
            <p style={{ fontSize: 22, fontWeight: "bold", color: '#e74c3c' }}>Quantidade: {produtos.reduce((acum, arr) => { return acum + parseInt(arr.quantidade) }, 0)}</p>
            {
              info.observacoes ?
                <div className="containerObservacao">
                  <p>Observações</p>
                  <p>{info.observacoes}</p>
                </div>
                : null
            }

            {
              !info.nome && <div className="imprimir" style={{ margin: '10px 0px', width: 200 }} onClick={handlePrint}>
                <FaPrint fill={'#3498db'} />
                Imprimir
              </div>
            }
          </div>
          {
            produtos.map((el) =>
              <ContainerItem key={el.id} dados={el} />
            )
          }

          {
            dados.observacao ?
              <div className="containerObservacao">
                <p>Observações</p>
                <p>{dados.observacao}</p>
              </div>
              : null
          }


        </div>
        {
          !info.nome && <div style={{ display: 'none' }}>
            <ComponentToPrint produtos={produtos} data={moment(info.data_).format('DD/MM/YYYY HH:mm')} obs={dados.observacao} ref={componentRef} />
          </div>
        }
      </div>
      <div className="containerRight">


        <p>Lançado em <b>{moment(dados.lancado).format('DD/MM/YYYY HH:mm')}</b> por <b>{dados.create_nome}</b></p>
        {
          dados.imagemobs ?
            <img alt={"Imagem de Observação"} src={baseURL + "/uploads/imagens/" + dados.imagemobs} />
            : null
        }
        {
          dados.audioobs ?
            <AudioPlayer
              src={baseURL + "/uploads/audios/" + dados.audioobs}
              onPlay={e => console.log("onPlay")}
            />
            : null
        }

        <p>Última atualização em <b>{moment(dados.atualizacao).format('DD/MM/YYYY HH:mm')}</b> por <b>{dados.update_nome}</b></p>
      </div>

    </>
  )
}
export default Index;